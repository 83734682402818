<template>
  <div class="card-item">
    <div class="image">
      <img v-if="product.images.length" :src="product.images[0].path" />
    </div>
    <div class="card-content">
      <div class="top">
        <div>
          <div
            class="avaliacao"
            :id="'avaliacao' + product.id"
            v-if="product.rating_count"
          >
            <img src="@/assets/img/icons/avaliacao.svg" />
            {{ Math.floor(product.rating_avg).toFixed(1) }}
            <span>({{ product.rating_count }})</span>
          </div>
          <b-tooltip
            v-if="product.rating_count"
            :target="'avaliacao' + product.id"
            :title="$t('store.text_1976')"
          />
        </div>
        <div class="categoria" v-if="product.category">
          <span>{{ product.category.name }}</span>
        </div>
      </div>
      <h4>{{ product.name }}</h4>

      <div class="comissao">
        <span>{{ $t('store.text_1977') }} </span>
        <p>
          R$ {{ formatMoney(calComissao(product.offers[0].amount, +product.comission)) }}
        </p>
      </div>
      <span class="preco"
        >{{ $t('store.text_1978') }} <br v-if="isMobile" />R$
        {{ formatMoney(product.offers[0].amount) }}</span
      >
    </div>
  </div>
</template>

<script>
import Money from "@/mixins/money";

export default {
  name: "CardProdut",
  mixins: [Money],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    taxa_afiliado: {
      type: Number,
      default: 0,
    },
    taxa_transacao: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    calComissao(preco, porcentagem) {
      const affiliate_fee = (preco * this.taxa_afiliado) / 100;

      const preco_taxado = preco - (this.taxa_transacao + affiliate_fee);

      const comissao = (preco_taxado * porcentagem) / 100;

      return comissao;
    },
  },
};
</script>

<style scoped>
.card-item {
  cursor: pointer;
  max-width: 300px;
  /* height: 405px; */
  border-radius: 10px;
  border: 1px solid #ededf0;
  overflow: hidden;
}
.card-item .image {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ededf0;
  min-width: 100%;
  max-width: 100%;
  height: 200px;
}
.card-item .image img {
  object-fit: contain;
  align-items: initial;
}
.card-content {
  padding: 20px;
}
.card-item .avaliacao {
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 600;
  font-size: 12px;
  max-width: 60px;
}
.card-item .avaliacao img {
  width: 12px;
}
.card-item .avaliacao span {
  font-weight: normal;
  color: #81858e;
}

.card-content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top .categoria span {
  font-size: 13px;
  color: #81858e;
}
.card-content h4 {
  margin: 10px 0;
  font-weight: 600;
  font-size: 15px;
  line-height: 1.5;
  min-height: 60px;
}

.card-content .comissao span {
  font-size: 12px;
  color: #81858e;
}
.card-content .comissao p {
  font-size: 24px;
  font-weight: bold;
  color: #2a63ab;
  margin-top: 7px;
}
.card-content .preco {
  color: #81858e;
  font-size: 13px;
  margin: 10px 0;
}
@media screen and (max-width: 768px) {
  .card-content .top {
    display: block;
  }
  .card-content .top .categoria {
    margin-top: 5px;
  }
  .card-content h4 {
    font-size: 14px;
    min-height: 40px;
  }
  .card-content .comissao p {
    font-size: 20px;
  }
}
</style>
